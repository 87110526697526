import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import './App.scss';
import Spinner from './components/Spinner'
import NotFound from './components/NotFound'
//Rounter
import PrivateRoute from './utils/privateRoute';
import PublicRoute from './utils/publicRoute';
// Toastify
import { ToastContainer, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AppWithRouterAccess from './AppWithRouterAccess';
//Loading
const loading = () => <Spinner></Spinner>;

function App() {
  return (
    <div className="App">
      <BrowserRouter>
          <React.Suspense fallback={loading()}>
            <Switch>
              <AppWithRouterAccess/>
              <Route path="*" component={NotFound} />
            </Switch>
          </React.Suspense>
      </BrowserRouter>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange
        draggable
        pauseOnHover
        transition={Zoom}
      />
    </div>
  );
}

export default App;
