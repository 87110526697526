import React from 'react';
const HomePage = React.lazy(() => import('./containers/HomePage'));
const LogoutPage = React.lazy(() => import('./containers/LogoutPage'));
const BookingPage = React.lazy(() => import('./containers/BookingPage'));
const BookingNewPage = React.lazy(() => import('./containers/BookingNewPage'));

const routes = [
  { path: '/', exact: true, name: 'Home', component: HomePage },
  { path: '/bookings', exact: true, name: 'Bookings ', component: BookingPage },
  { path: '/bookings/new', exact: true, name: 'Booking New', component: BookingNewPage},
  { path: '/logout', exact: true, name: 'Logout', component: LogoutPage}
];

export default routes 