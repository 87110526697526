import React from "react";
import { useLocalStore } from "mobx-react";
import { mainAxios } from '../utils/appApi';

export const StoreContext = React.createContext();

const StoreProvider = ({ children }) => {
  const store = useLocalStore(() => ({
    step: 0,
    cycle_id: 0,
    office_id: 0,
    date: "",
    viewing_room_id: 0,
    start_time: "",
    end_time: "",
    no_of_person: 1,
    cycles: [],
    offices: [],
    cycles_info: [],
    bookings: [],
    booking: {},
    filter_by_cycle_id: 0,
    delete_booking_id: 0,
    header: 'DEBEERS BOOKING APP',
    duration_max_hours: 0,
    display_text: {
      available_type: "",
      available_title: "",
      room_name: "",
      room_time: "",
      cycle_name: ""
    },
    fetchCycles: async () => {
      const response = await mainAxios.get('cycles');
      store.cycles = response.data;
    },
    fetchOffices: async () => {
      const response = await mainAxios.get('offices');
      store.offices = response.data;
    },
    fetchCyclesInfo: async (cycle_id = store.cycle_id, office_id = store.office_id) => {
      const response = await mainAxios.get('cycles/info', {
        params:{
          cycle_id: cycle_id,
          office_id: office_id
        }
      });
      store.cycles_info = response.data;
      return response
    },
    createBooking: async () => {
      const response = await mainAxios.post('bookings', {
        date: store.date,
        office_id: store.office_id,
        cycle_id: store.cycle_id,
        viewing_room_id: store.viewing_room_id,
        start_time: store.start_time,
        end_time: store.end_time,
        no_of_person: store.no_of_person
      });

      return response;
    },
    fetchBookings: async () => {
      const response = await mainAxios.get('bookings');

      store.bookings = response.data;
    },
    getBooking: async(id) => {
      const response = await mainAxios.get(`/bookings/${id}`);

      store.booking = response.data;
    },
    deleteBooking: async (id) => {
      const response = await mainAxios.delete(`bookings/${id}`);
      
      if(response.status === 200){
        store.delete_booking_id = id;
        store.bookings = store.removeBooking;
      }

      return response
    },
    nextStep: () => {
      store.step += 1;
    },
    resetStep: () => {
      store.step = 0;
      store.cycle_id = 0;
      store.office_id = 0;
      store.date = "";
      store.viewing_room_id = 0;
      store.start_time = "";
      store.end_time= "";
      store.no_of_person = 1;
      store.duration_max_hours = 0;
      store.display_text = {
        available_type: "",
        available_title: "",
        room_name: "",
        room_time: "",
      };
    },
    setDurationMaxTime: (customer) => {
      store.cycles_info.bookings.reduce((max_time, current) => {
        if( current.customer === customer ) store.duration_max_hours += parseFloat(current.duration)
        return max_time;
      }, [])
    },
    get filterBookingByCycle() {
      if(store.filter_by_cycle_id === 0) return store.bookings;
      return store.bookings.filter(booking => booking.cycle_id === store.filter_by_cycle_id)
    },
    get removeBooking() {
      return store.bookings.filter(booking => booking.id !== store.delete_booking_id);
    },
    get isLimitTime() {
      return store.duration_max_hours < parseInt(store.cycles_info.max_time)/60
    },
    get maxTimeRoomSelect() {
      return parseInt(store.cycles_info.max_time)/60 - store.duration_max_hours;
    },
    get maxTimeDisplay() {
      return parseInt(store.cycles_info.max_time) - store.duration_max_hours*60;
    }
  }));

  return (
    <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
  );
};

export default StoreProvider;