import React, { useEffect, useContext, useState } from 'react';
import $ from "jquery";
import { getUserName, removeUserSession } from '../../utils/userSession';
import { Link, useHistory } from "react-router-dom";
import Logo from '../../assets/images/common/logo03.png';
import LogoutIcon from '../../assets/images/common/icon12.svg';
import UserIcon from '../../assets/images/common/icon13.svg';
import { toast } from 'react-toastify';
import { useObserver } from "mobx-react";
import { StoreContext } from '../../store/storeProvider';
import Modal from '../../components/Modal';
import ModalTitle from '../Modal/ModalTitle';
import { useOktaAuth } from "@okta/okta-react";
const BOOKING_TITLE  = "RESERVE A SIGHT ROOM"

function Header({props}) {
  const { authState, oktaAuth } = useOktaAuth();
  const username = getUserName();
  const store = useContext(StoreContext);
  const history = useHistory();
  
  const handleLogout = () => {
    if(store.header !== BOOKING_TITLE){
      logout();
    }
  }

  const handleConfirmLogout = () => {
    logout();
  }

  const logout = () => {
    if(authState?.isAuthenticated) oktaAuth.signOut();
    removeUserSession();
    // history.push('/login');
    window.location.href = 'https://preprod.explore.debeersgroup.com/';
    toast.success('Signed out successfully.');
  }

  useEffect(() => {
    $("#MenuBtn").on("click", function () {
      $(this).toggleClass("active");
      $(".header_sp_menu").fadeToggle();
    });
  }, []);

  return useObserver(() => (
    <header className="header_type02">
      <div className="header_wrap">
        <div className="header_inner">
          <div className="header_top">
            <div className="header_top_group_logo">
              <Link to="/" className="header_logo">
                <h1>
                  <img src={Logo} alt="Debbers Logo" />
                </h1>
              </Link>
            </div>
            <p className="header_top_title">{store.header}</p>
            <div className="common_pc">
              <div className="header_info">
                <p className="header_user">
                  {username}
                </p>
                <button type="button" className="header_logout_btn btn_Modal_Logout" onClick={handleLogout}>
                  <img src={LogoutIcon} alt="LogoutIcon" />Logout
                </button>
              </div>
            </div>
            <div className="common_sp">
              <button type="button" className="header_menu_btn white" id="MenuBtn">
                <img src={UserIcon} alt="UserIcon" />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="common_sp">
        <div className="header_sp_menu">
          <p className="header_user">
            {username}
          </p>
          <button type="button" className="header_logout_btn btn_Modal_Logout" onClick={handleLogout}>
            <img src={LogoutIcon} alt="" />Logout
          </button>
        </div>
      </div>
      <Modal 
        modalId="Modal_Logout"
        title="Booking has not been confirmed. You will lose your current booking. Please press Back to return to the booking or press Continue to log-out."
      >
        <div className="group_footer_modal_button">
          <button className="btn_footer_modal btn_modal_cancel">BACK</button>
          <button className="btn_footer_modal btn_modal_confirm" onClick={handleConfirmLogout}>CONTINUE</button>
        </div>
      </Modal>
    </header>
  ))
}

export default Header;
