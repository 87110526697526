import React, { useEffect } from 'react';
import $ from 'jquery';
import './modal.scss'
import ModalTitle from './ModalTitle';
import ModalSubTitle from './ModalSubTitle';
import ModalClose from './ModalClose';

function Modal(props) {
  const bookingModalClass = props.isBookingModal ? ' modal_booking modal_room_picker ' : ' ';
  const modalId = props.modalId;

  useEffect(() => {
    $(`.btn_${modalId}`).on("click", function () {
      $(".modal_filter").fadeIn();
      $(`.${modalId}`).show();
    });

    $(".modal_filter, .btn_modal_cancel, .btn_modal_booking_confirm").on("click", function () {
      $('.modal_filter').fadeOut();
      $(`.${modalId}`).hide();
    });

    if(props.isOpen){
      $(".modal_filter").fadeIn();
      $(`.${modalId}`).show();
    }

    return () => {
      $('.modal_filter').fadeOut();
      $(`.${modalId}`).hide();
    }
  }, []);

  return (
    <div>
      <div className="modal_filter" />
      <div className={"modal_block" + bookingModalClass + modalId}>
        <ModalClose isClose={props.isClose}></ModalClose>
        <ModalTitle title={props.title} />
        <ModalSubTitle sub_title={props.sub_title} />
        {props.children}
      </div>
    </div>
  );
}

export default Modal;