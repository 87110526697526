import React from 'react';
import iconClose from '../../plugins/roompicker/e-remove.svg';

function ModalClose({isClose}) {
  if(!isClose){
    return null;
  }

  return (
    <div className="icon_modal_close"><img className="btn_modal_cancel" src={iconClose} alt="iconClose" /></div>
  );
}

export default ModalClose;