import React, { useState } from "react";
import { css } from "@emotion/core";
import BarLoader from "react-spinners/BarLoader";
import BeatLoader from "react-spinners/BeatLoader";


// Can be a string as well. Need to ensure each key-value pair ends with ;
const override = css`
  display: block;
  margin: 0 auto;
  border-color: #0d1a29;
  text-align: center;
`;

export default function Spinner() {
  const [loading] = useState(true);

  return (
    <div className="sweet-loading">
      <BarLoader
        css={override}
        height={5}
        width={'100%'}
        color={"#0d1a29"}
        loading={loading}
      />
    </div>
  );
}

export const SyncSpinner = () => {
  const [loading] = useState(true);

  return (
    <div className="sweet-loading">
      <BeatLoader
        css={override}
        size={15}
        margin={2}
        color={"#0d1a29"}
        loading={loading}
      />
    </div>
  );
}